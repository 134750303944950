import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { defaultJson } from '../../Utils/Helpers/defaultTemplate';
import { useApplicationContext } from '../../Context/applicationContext';
import { UploadDocumentContextProvider } from '../../Context/uploadDocumentContext';
import {
  getApplicationByApplicationId,
  getContactInfoByContactId,
  getFormByFormId,
  getBanks
} from '../../Utils/apiServices';
import {
  getCurrentStepPayload,
  getVisibleSteps,
} from '../../Utils/Helpers/applicationHelper';
import CustomizedSteppers from '../KenApplicationForm/Components/KenStepperHeader';
import KenApplicationFormHeader from '../KenApplicationFormHeader';
import KenButton from '../KenButton';
import Footer from './Footer';
import Stepper from './Stepper';
import { Container } from '@mui/material';
import InstructionsStep from './InstructionsStep';
import { AuthContext } from '../../Context/authContext';
import FormHeader from '../../Containers/ApplicationForm/Components/FormHeader';
import { useTranslation } from 'react-i18next';

export default function KenApplication() {
  const navigate = useNavigate();
  const formRef = useRef();
  const { t } = useTranslation();

  const urlSearch = useLocation().search;

  const {
    state: { user },
  } = useContext(AuthContext);

  const { applicationState, applicationDispatch } = useApplicationContext();

  const {
    formId,
    applicationId,
    applicationData,
    form,
    steps = [],
    activeStep,
    showHeader,
  } = applicationState;

  const [isDataFetched, setDataFetched] = useState(false);
  useEffect(() => {
    if (steps[activeStep] && applicationId && applicationData && form) {
      setDataFetched(true);
    }
  }, [steps, activeStep, applicationId, applicationData, form]);

  //update context state
  const updateState = (payload) => {
    applicationDispatch({ type: 'update', payload });
  };

  //
  const goToDashboard = () => {
    navigate('/home');
  };

  // TODO: Implement alert/toast
  const alertMessage = (message) => {
    console.log('alert:', message);
  };

  // update applicant id to context
  useEffect(() => {
    updateState({ applicantId: user?.applicantId });
  }, [user]);

  // get formid and applicationid from query
  useEffect(() => {
    console.log('KenAppli useEffect: urlSearch :', urlSearch);
    const query = new URLSearchParams(urlSearch);

    const tempId = query.get('formId');

    if (tempId && tempId !== 'null' && tempId !== 'undefined') {
      //update formId in context
      updateState({ formId: tempId });
    } else {
      // alert user that form is invalid
      alertMessage('Invalid formId. Try again');
      // redirect to dasboard
      goToDashboard();
    }

    const id = query.get('applicationId');
    if (id) {
      updateState({ applicationId: id });
    }
  }, [urlSearch]);

  const getCampuses = (campuses) => {
    const data = [];
    campuses.forEach((el) => {
      if (el.combinationId && el.combinationName) {
        data.push({ label: el?.combinationName, value: el?.combinationId });
      }
    });
    return [...data];
  };

  // actions to be performed if formId
  useEffect(() => {
    console.log('formId: ', formId);
    if (!formId) {
      return;
    }

    getFormByFormId(formId)
      .then(async (res) => {
        // if success update context else throw alert to refresh
        if (res?.success) {
          //Extract only steps to be used
          const steps = getVisibleSteps(res?.data?.formMetadata);
          console.log(res);
          // upodate context with formOptions from form data
          // NOTE: campuses, specializations are had coded picklist values from academic applications. Update formOptions if any picklist values are to be called using api
          console.log('Form :currentStep: ', res?.data, activeStep);
          updateState({
            form: { ...res?.data },
            steps,
            totalSteps: steps.length,
            ...(applicationId ? {} : { activeStep: 0 }),
            formOptions: {
              bankList: await getBankList(),
              campuses: getCampuses(res?.data?.campuses),
              specializations: res?.data?.specializations?.map((s) => ({
                label: s?.specializationName,
                value: s?.recordId,
              })),
            },
          });
        } else {
          alertMessage('Refresh and try again');
        }
      })
      .catch((err) => {
        const { reponse: { status, data } = {} } = err;
        console.log('form err: ', err);
        if (status === 400) {
          //check if invalid application
          // goToDashboard()
          alertMessage('Invalid form Try again');
        }
      });
  }, [formId]);

  const getBankList = async () => {
    const data = await getBanks()
    if (data.length > 0) {
      return data.map((el) => {
        return { label: el?.bankName, value: el?.bankName }
      });
    } else {
      return []
    }
  };

  const combineNames = (applicant) => {
    const firstName = applicant.firstName || '';
    const middleName = applicant.middleName || '';
    const lastName = applicant.lastName || '';
    return `${firstName} ${middleName} ${lastName}`.trim();
  };



  //actions to be performed if applicationId
  useEffect(() => {
    console.log('appId: ', applicationId);
    if (!applicationId) {
      return;
    }
    // updated the code to convert to religion
    getApplicationByApplicationId(applicationId)
      .then((res) => {
        // if success update context else throw alert to refresh
        console.log(
          'ApplicationData: currentStep ',
          parseInt(res?.data?.application?.currentStep) || 0
        );
        if (res?.success && res?.data) {
          let tempArray = res.data;
          if(tempArray?.applicant){
            if(!tempArray.applicant?.fatherName){
            tempArray.applicant.fatherName=tempArray.applicant.middleName
            }

          }
          if (tempArray?.applicant?.approxMonthlyIncome?.endsWith('.00')) {
            tempArray.applicant.approxMonthlyIncome = tempArray.applicant.approxMonthlyIncome.slice(0, -3);  // Remove the '.00' part
          }

          if (
            tempArray?.applicant?.religion &&
            tempArray?.applicant?.residence
          ) {
            // Convert the value of 'religion' to uppercase
            tempArray.applicant.religion =
              tempArray.applicant.religion?.toUpperCase();
            tempArray.applicant.residence =
              tempArray.applicant.residence?.toUpperCase();
          }

          if (
            tempArray?.applicant?.mothersName

          ) {
            // Convert the value of 'religion' to uppercase
            tempArray.applicant.mothersName = tempArray.applicant.mothersName?.toUpperCase();
          }
          if (tempArray?.importantInformation) {
            tempArray.importantInformation =
              tempArray.importantInformation.toUpperCase();
          }

          if (defaultJson.stepId == 'familyInfo') {
            res.data.application.status = 'Appplication incomplete';
          }

          console.log(tempArray, '65u56u');

          if ((tempArray['applicantRelations'] || []).length === 0) {
            tempArray['applicantRelations'] = [
              {
                firstName: tempArray.applicant.fatherName,
                lastName: tempArray.applicant.lastName
              },
            ];
          }

          const fullName = combineNames(tempArray.applicant);
          tempArray.applicant.accountHoldername =fullName
          // Process the applicant data as needed
          if (tempArray?.applicantRelations?.length > 0) {
            const applicant = tempArray.applicantRelations[0];
            const {
              noOfFamilyMembers,
              noOfFamilyMembersAdults,
              noOfFamilyMembersChildren,
              nonWorkingMembers,
            } = applicant;

            // Validation check
            if (
              noOfFamilyMembersAdults > noOfFamilyMembers ||
              noOfFamilyMembersChildren > noOfFamilyMembers ||
              nonWorkingMembers > noOfFamilyMembers
            ) {
              console.log("Family Members Data:", {
                noOfFamilyMembers,
                noOfFamilyMembersAdults,
                noOfFamilyMembersChildren,
                nonWorkingMembers,
              });

              console.error("Validation error: Family members count exceeds total members.");
              // Optionally update the application status or error state
              tempArray.application.status = "Validation error: Family members count exceeds total members.";
            }
            else {
              console.log("hello error")
            }
          }


                    // Process the applicant data as needed
                    if (tempArray?.applicantRelations?.length > 0) {
                      const applicant = tempArray.applicantRelations[0];
                      const {
                        noOfFamilyMembers,
                        noOfFamilyMembersAdults,
                        noOfFamilyMembersChildren,
                        nonWorkingMembers,
                      } = applicant;
          
                      // Validation check
                      if (
                        noOfFamilyMembersAdults > noOfFamilyMembers ||
                        noOfFamilyMembersChildren > noOfFamilyMembers ||
                        nonWorkingMembers > noOfFamilyMembers
                      ) {
                        console.log("Family Members Data:", {
                          noOfFamilyMembers,
                          noOfFamilyMembersAdults,
                          noOfFamilyMembersChildren,
                          nonWorkingMembers,
                        });
                        
                        console.error("Validation error: Family members count exceeds total members.");
                        // Optionally update the application status or error state
                        tempArray.application.status = "Validation error: Family members count exceeds total members.";
                      }
                      else{
                        console.log("hello error")
                      }
                    }      
                    
          updateState({
            applicationData: tempArray,
            activeStep: parseInt(res?.data?.application?.currentStep) || 0,
            // activeStep: 6,
          });
        }
      })

      .catch((err) => {
        const { response: { status, data: { errorMessages = [] } = {} } = {} } =
          err;
        console.log('application err: ', err);
        if (status === 400 && errorMessages?.length > 0) {
          //check if invalid application
          const errObj = errorMessages.find(
            (el) => (el.errorMessage = 'Wrong application Id.')
          );
          if (errObj) {
            goToDashboard();
            alertMessage(t('message:Application_Invalid'));
          }
        }
      });
  }, [applicationId]);

  // if formId or applicationId not valid redirect to dashboard

  // Footer next handler:
  // submit form
  const handleNext = (e) => {
    // applicationDispatch({ type: 'nextStep' })

    // triggering submit
    formRef?.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    );
  };

  const handlePrev = (e) => {
    applicationDispatch({ type: 'prevStep' });
  };

  //Step changes:
  // change json tempate for formBuilder/stepcontent by active step
  useEffect(() => {
    console.log('active step, steps change: ');
  }, [activeStep, steps]);

  //onSave
  // no validations >>
  // get values from context and call api

  const handleSave = (formValues = {}) => {
    // If any errors in form show alert message

    // payload based on the current steps and sections
    const payload = getCurrentStepPayload(
      formValues,
      applicationData,
      steps,
      activeStep
    );

    // filter undefined objects

    // call api to update the

    // on error alert to enter all the mandatory fields
  };

  // TODO: input event to be replaced with formValues
  const handleSubmit = (e) => {
    console.log('submitted:');

    const formValues = {};

    const payload = getCurrentStepPayload(
      formValues,
      applicationData,
      steps,
      activeStep
    );
    console.log(payload);
    // TODO: call api to update

    // TODO: remove below
    e.preventDefault();
  };

  return (
    <div>
      <FormHeader />
      {showHeader && (
        <>
          {form?.programName ? (
            <KenApplicationFormHeader
              programName={form?.programName}
              duration={form?.courseDuration}
              batch={form?.courseBatch}
              endDate={form?.endDate}
            />
          ) : (
            <Skeleton
              height="8px"
              style={{
                marginTop: '70px',
                marginBottom: '20px',
                background: '#F6C445',
              }}
            />
          )}
        </>
      )}
      {/* Add condition when application data is fetched */}
      {/* Render instruction for first time when no application id found */}
      {!applicationId && form && <InstructionsStep />}
      {isDataFetched && (
        <>
          {/* KenApplication

                <KenButton onClick={handlePrev}>Prev</KenButton>

                ActiveStep:{activeStep}

                <KenButton onClick={handleNext}>Next</KenButton> */}
          {/* <form onSubmit={handleSubmit} ref={formRef}>
                    {getSections().map((step)=>{
                        return (<Stepper step={step} />)
                    })
                    }
                    <KenButton type="submit">Submit</KenButton>
                </form> */}
          <CustomizedSteppers activeStep={activeStep} steps={steps} />
          <UploadDocumentContextProvider>
            <Stepper step={steps[activeStep]} formRef={formRef} />
          </UploadDocumentContextProvider>
        </>
      )}
      {((applicationId && !applicationData) || !form) && (
        <Container maxWidth="md" style={{ textAlign: 'start' }}>
          <Skeleton
            width="40%"
            height="30px"
            style={{ marginTop: '50px', marginBottom: '20px' }}
          />
          <Skeleton
            count={2}
            inline
            width="45%"
            wrapper={({ children }) => (
              <span style={{ marginRight: '0.5rem' }}>{children}</span>
            )}
          />

          <Skeleton
            width="40%"
            height="30px"
            style={{ marginTop: '50px', marginBottom: '20px' }}
          />
          <Skeleton
            count={2}
            inline
            width="45%"
            wrapper={({ children }) => (
              <span style={{ marginRight: '0.5rem' }}>{children}</span>
            )}
          />
        </Container>
      )}
    </div>
  );
}
